var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4e637ff7"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/lx-mf";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

// YOU MUST PUT ALL THE CODE HERE. DO NOT IMPORT FUNCTIONS/HELPERS BECAUSE THEY ARE NOT GOING TO BE COMPILED
// that's why we are just copy/pasting this content in each microfrontend

import * as Sentry from '@sentry/nextjs';

// react-all-pages sentry project
const defaultDsn =
  'https://0d3a269a086c4662aa4cbfbd338591a8@o383086.ingest.us.sentry.io/5224641';
const SENTRY_DSN =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN || defaultDsn;
export const enabled =
  process.env.APP_ENV === 'production' &&
  process.env.SENTRY_ORG &&
  process.env.SENTRY_PROJECT;

const initSentryForClient = () => {
  if (!enabled) {
    return;
  }

  if (SENTRY_DSN === defaultDsn) {
    console.warn(
      '> Sentry is being initialized with a the default DNS (react-all-pages). Please set the correct SENTRY_DNS env var with the value of the sentry project that belongs to the current project.',
    );
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    debug: false,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.0,
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,

      // custom crehana urls
      'https://fast.wistia.com/',
      'https://saltcdn2.googleapis.com',
      'https://player.vimeo.com/video',
      'https://cdn.mxpnl.com',
      'https://cdn.datagran.io',
      'https://static.hotjar.com',
      'https://vars.hotjar.com',
      'https://vc.hotjar.io',
    ],
    ignoreErrors: [
      // Error initializing google auth in some environments
      'idpiframe_initialization_failed',
      /^Object Not Found Matching Id$/,
      // default config
      /.*ChunkLoadError.*/,
      /.*Invariant Violation.*/,
      /.*Network error.*/,
      // Handled player errors
      /.*fullscreen.*/,
      /.*InvalidStateError.*/,
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // others
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: NetworkError when attempting to fetch resource.',
      'Event: Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type',
      'NetworkError',
      'UnhandledRejection: Non-Error promise rejection captured with value:',
      'TypeError: cancelado',
      "SyntaxError: Unexpected token ';'",
      "Error: SecurityError: Failed to execute 'sendBeacon' on 'Navigator': sendBeacon() with a Blob whose type is not any of the CORS-safelisted values for the Content-Type request header is disabled temporarily. See http://crbug.com/490015 for details.",
      'TypeError: Illegal invocation',
      'SyntaxError: Use of const in strict mode.',
      '[Network Error]: TypeError: Failed to fetch',
      'Error: Network error: Failed to fetch',
      'Error: [object Object]',
      // Error initializing google auth in some environments
      'idpiframe_initialization_failed',
      'UnhandledRejection: Non-Error promise rejection captured with value:',
      /Can't find variable: al_onPoststitialDismiss/,
      /Can't find variable: al_onAdViewRendered/,
      'Failed to fetch',
      'Loading chunk',
      'ChunkLoadError',
    ],
    beforeSend(event, hint) {
      const error = hint && hint.originalException;
      if (
        error &&
        error instanceof Error &&
        error.message &&
        error.message.match(/Can't find variable: al_onPoststitialDismiss/)
      ) {
        return null;
      }
      return event;
    },
  });
};

initSentryForClient();

import { B2B_TEAM_COURSES } from '../constants';
import { TPageTranslationLocale } from './types';

const translationsPT: TPageTranslationLocale = {
  [B2B_TEAM_COURSES]: {
    EMPTY_SUBTITLE: 'Peça para o seu administrador atribuir uma rota a você',
    EMPTY_SUBTITLE_B2B_ALL_CATALOG:
      'Descubra nosso vasto catálogo, enquanto seu administrador atribui uma rota a você.',
    ASSIGNED_TRACK:
      'Ainda não atribuíram nada a você. Logo mais vai poder iniciar a sua experiência.',
    ASSIGNED_TRACK_B2B_ALL_CATALOG:
      'Ainda não atribuíram rotas a você, mas já pode começar a sua experiência de aprendizagem.',
    EXPIRATION_DATE: 'DATA DE ENTREGA:',
    STUDENTS: 'Alunos',
    END_DATE: 'Data final {{finalDate}}',
    TITLE_MINIMUM_REQUIREMENTS:
      'Requisitos para concluir a rota de aprendizagem.',
    CTA_START_COURSE: 'Começar curso',
    CTA_CONTINUE_PATH: 'Continuar rota',
    REWARDS_TITLE: 'O que são os meus Rewards?',
    REWARDS_SUBTITLE: 'Saiba mais',
    REWARDS_COPY1:
      'Os Rewards são \n bônus de estudo \n para acessar cursos \n livres',
    REWARDS_COPY2:
      'Quando terminar sua rota \n de aprendizagem, você poderá \n escolher seus cursos \n preferidos.',
    REWARDS_COPY3:
      'Você terá \n todos os cursos \n do catálogo da Crehana ao seu dispor.',
    REWARDS_CTA: 'Entendi',
    REWARDS_UNLOKED: 'Rewards bloqueados',
    REWARDS_INFO:
      'Depois de completar seu training, você desbloqueou {{totalCourses}} curso(s) adicional(s) na Crehana, à sua escolha.',
    ENROLLED_PATH_COURSE_PROGRESS_LABEL: '{{progress}}% aulas assistidas',
    ENROLLED_PATH_COURSE_PROJECT_LABEL: 'Projeto final:',
    SKILL_XP_CARD_RESULTS_CTA: 'Ver Resultado',
    SKILL_XP_CARD_PENDING_CTA: 'Fazer Skill XP',
    PROJECT_DIALOG_TITLE:
      'Selecione um curso sobre o qual você queira realizar o projeto.',
    PROJECT_DIALOG_DESCRIPTION:
      'Lembre-se de que para obter seu certificado de rota é obrigatório realizar 1 projeto, referente ao curso que você escolher. **E se você quiser fazer todos?** Pode fazer! Cada projeto será avaliado por um tutor e você receberá um feedback.',
    PROJECT_DIALOG_DESCRIPTION_MOBILE:
      'Lembre-se de que para obter seu certificado de rota é obrigatório realizar pelo menos 1 projeto final, à sua escolha.',
    PROJECT_DIALOG_COURSES_TITLE: 'Cursos sem iniciar projeto',
    PROJECT_DIALOG_DELIVERABLE_TITLE: 'Tarefa do curso',
    PROJECT_TYPE_CERTIFIED: 'Certificado',
    PROJECT_TYPE_DRAFT: 'Pendente',
    PROJECT_TYPE_FINISHED: 'Concluído',
    PROJECT_TYPE_OBSERVED: 'Com comentários',
    PROJECT_TYPE_PUBLISHED: 'Em revisão',
    PROJECT_TYPE_RESOLVED: 'Pendente',
    PROJECT_DIALOG_SELECT_COURSE_PLACEHOLDER:
      'Selecione um curso para ver a entrega aqui.',
    PROJECT_DIALOG_PENDING_PROJECT_SUBTITLE: 'Último proyecto en curso',
    LEARN_ENROLLED_PATH_PANEL_COURSES_TITLE:
      'Escolha seu projeto final e complete a rota de aprendizagem.',
    LEARN_ENROLLED_PATH_PANEL_COURSES_TITLE__plural:
      'Escolha {{count}} cursos para realizar os projetos finais e complete a rota.',
    LEARN_ENROLLED_PATH_PANEL_PENDING_PROJECT_TITLE:
      'Continue seu projeto final e receba o certificado da rota de aprendizagem.',
    ENROLLED_PATH_START_PROJECT_LABEL: 'Inicie o projeto',
    ENROLLED_PATH_CONTINUE_PROJECT_LABEL: 'Continue o projeto',
    ENROLLED_PATH_START_DOWNLOAD_CERTIFICATE: 'Baixe o certificado',
    REWARD_CARD_TRACK_IN_PROGRESS_TITLE:
      'Ótimo trabalho até agora, {{username}}!',
    REWARD_CARD_TRACK_IN_PROGRESS_SUBTITLE:
      'Continue completando a sua rota de esudo para que possa escolher cursos livres, do seu interesse.',
    REWARD_CARD_TRACK_IN_PROGRESS_CTA: 'Saiba mais',
    REWARD_CARD_TRACK_NOT_STARTED_TITLE:
      'Você tem {{count}} cursos livres para escolher!',
    REWARD_CARD_TRACK_NOT_STARTED_SUBTITLE:
      'Complete a sua rota de aprendizagem para escolher os {{count}} cursos livres que quiser.',
    REWARD_CARD_TRACK_NOT_STARTED_CTA: 'Saiba mais',
    REWARD_CARD_TRACK_COMPLETED_TITLE: 'Ótimo trabalho, {{username}}!',
    REWARD_CARD_TRACK_COMPLETED_SUBTITLE:
      'Agora que concluiu sua rota de estudo, vai poder acessar seus cursos preferidos.',
    REWARD_CARD_TRACK_COMPLETED_CTA: 'Escolher meus cursos',
    STATUS_ITEM_IN_PROGRESS: 'Em andamento',
    STATUS_ITEM_MANDATORY_COURSES: 'Obrigatórios',
    STATUS_ITEM_MANDATORY_PROJECTS: 'Projeto',
    STATUS_ITEM_MANDATORY_PROJECTS_plural: 'Projetos',
    WARNING_DAYS_REMAINING: 'Falta {{count}} dia',
    WARNING_DAYS_REMAINING_plural: 'Faltam {{count}} dias',
    WARNING_DAYS_DUE: 'Atraso de {{count}} dia',
    WARNING_DAYS_DUE_plural: 'Atraso de {{count}} dias',
    COMPLETE_ALL_COURSES_MESSAGE_TITLE: 'Como obter um certificado?',
    COMPLETE_ALL_COURSES_MESSAGE:
      'Você deve assistir 100% dos cursos obrigatórios e ter 100% dos projetos obrigatórios aprovados.',
    LEARNING_PATHS_LEFT_SIDE_TITLE: 'Rota de aprendizagem',
    LEARNING_PATHS_LEFT_SIDE_TITLE_plural: 'Trilhas de aprendizagem',
    LEARNING_TEAM_TRACK_CONTENT_FOR_YOU: 'Ver conteúdo para você',
    LEARN_TEAM_TRACK_COMPLETE_SKILLPATH: 'Complete o Skill XP',
    LEARN_TEAM_TRACK_X_ASSIGNED_COURSES: '{{count}} Atribuído',
    LEARN_TEAM_TRACK_X_ASSIGNED_COURSES_plural: '{{count}} Atribuídos',
    LEARN_TEAM_TRACK_X_MANDATORY_COURSES: '{{count}} Obrigatório',
    LEARN_TEAM_TRACK_X_MANDATORY_COURSES_plural: '{{count}} Obrigatórios',
    LEARN_TEAM_TRACK_X_MANDATORY_PROJECTS: '{{count}} Projeto',
    LEARN_TEAM_TRACK_X_MANDATORY_PROJECTS_plural: '{{count}} Projetos',
    LEARN_TEAM_TRACK_COMPLETED: 'Completado',
    LEARN_TEAM_TRACK_REWARD_QUANTITY: 'Ganaste',
    LEARN_TEAM_TRACK_SUCCESS: 'Você concluiu o caminho de aprendizado!',
    LEARN_TEAM_TRACK_SUCCESS_LABEL: 'Você conseguiu',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_START_CURSO: 'Iniciar curso',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_START_EVALUATION: 'Iniciar avaliação',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_START_PROJECT: 'Iniciar projeto',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_VIEW_TRAILER: 'Assistir ao trailer',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_GO_BACK: 'Voltar',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_OPEN_CONTENT: 'Abrir conteúdo',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_MARK_AS_WATCHED: 'Marcar como visualizado',
    LEARN_TEAM_TRACK_MEDIUM: 'Você está indo muito bem, continue assim!',
    LEARN_TEAM_TRACK_PROJECT_LABEL: 'Projeto',
    LEARN_TEAM_SKIP: 'Não agora',
    LEARN_TEAM_EXTERNAL: 'Conteúdo externo',
    LEARN_TEAM_EXTERNAL_ASK: 'Deseja marcar o conteúdo como visualizado?',
    LEARN_TEAM_TRACK_TOOLTIP_BLOCK:
      'Você deve concluir os cursos anteriores para visualizá-los',
    LEARN_TEAM_TRACK_BLOCK_DESCRIPTION:
      'Para iniciar este curso, você deve primeiro ver os seguintes cursos:',
    LEARN_TEAM_MORE: 'Veja mais',
    LEARN_TEAM_HELP:
      'Complete sua trilha de aprendizado e você poderá escolher *{{courseCount}} cursos gratuitos da Crehana* de seu interesse',
    LEARN_TEAM_TRACK_MANDATORY_LABEL: 'Curso obrigatório',
    LEARN_TEAM_HELP_ONE:
      'As recompensas são bônus de estudo para acesso a cursos gratuitos',
    LEARN_TEAM_HELP_TWO:
      'No final do seu caminho de aprendizado, você poderá escolher seus cursos favoritos',
    LEARN_TEAM_HELP_THREE:
      'Você terá acesso a todos os cursos do catálogo da Crehana',
    LEARN_TEAM_TRACK_OPTIONAL_LABEL: 'Curso opcional',
    LEARN_TEAM_TRACK_OPTIONAL_LABEL_SHORT: 'opcional',
    LEARN_TEAM_TRACK_CUSTOM_LABEL: 'Personalizado',
    LEARN_TEAM_TRACK_SHOW_PROJECT: 'Mostrar projecto',
    LEARN_TEAM_TRACK_CONTINUE_PROJECT: 'Continuar o projecto',
    LEARN_TEAM_TRACK_CHOOSE_MISSING_PROJECTS: 'Escolha dos projectos em falta',
    LEARN_TEAM_TRACK_START_PROJECT: 'Iniciar projeto final',
    LEARN_TEAM_TRACK_ACADEMY_TAG: 'Academia',
    LEARN_TEAM_TRACK_COURSE_CARD_ARIA_LABEL_CTA: 'Continuar o curso',
    LEARN_TEAM_SEE_LESS: 'Ver menos cursos',
    LEARN_TEAM_SEE_MORE: 'Veja mais percursos na rota',
    LEARN_TEAM_STATE_INTEGRATION: 'Marcar como visto',
    COURSE_CARD_COURSE_REQUIRED: 'curso{{s}} obrigatório{{s}}',
    COURSE_CARD_REQUIREMENTS_POPUP_TITLE: 'Cursos obrigatórios',
    COURSE_CARD_REQUIREMENTS_POPUP_SUBTITLE:
      'Para poder iniciar este curso, você deve ver previamente o seguinte:',
    COURSE_CARD_REQUIREMENTS_POPUP_CTA: 'Entendido',
    LEARN_TEAM_HEADER_OPTION_ALL: 'Todos',
    LEARN_TEAM_HEADER_OPTION_DEFEATED: 'A ser derrotado',
    LEARN_TEAM_HEADER_OPTION_EXPIRED: 'Expirado',
    LEARN_TEAM_HEADER_OPTION_DISABLED: 'Desativado',
    LEARN_TEAM_HEADER_OPTION_COMPLETEDS: 'Completadas',
    LEARN_TEAM_OPTION: 'Mais ações',
    LEARN_TEAM_TIME: 'Tempo restante: {{duration}}',
    LEARN_TEAM_TIME_DURATION: 'Duração do curso: {{duration}}',
    LEARN_TEAM_COURSE_REQUIRED_LABEL: 'Exibir curso',
    LEARN_TEAM_QUIZ_REQUIRED_LABEL: 'Passar na avaliação',
    LEARN_TEAM_PROJECT_REQUIRED_LABEL: 'Passar no projeto',
    LEARN_TEAM_HEADER_SEARCH: 'Pesquisar por caminho ou título',
    LEARN_TEAM_EMPTY_TITLE: 'Você não tem caminhos atribuídos',
    LEARN_TEAM_EMPTY_FILTER_NONE: 'Tente outro nome',
    LEARN_TEAM_HEADER_FILTER: 'FILTROS',
    LEARN_TEAM_HEADER_DETAIL_DISABLED_DESCRIPTION:
      'Essa rota foi desativada pelo seu administrador',
    LEARN_TEAM_CRITERIA: 'Critérios de certificação',
    LEARN_TEAM_HEADER_DETAIL_DEFEATED: 'Vence',
    LEARN_TEAM_HEADER_DETAIL_EXPIRED: 'Vencio',
    LEARN_TEAM_HEADER_DETAIL_ASSIGNED: 'Atribuído por',
    LEARN_TEAM_HEADER_DETAIL_REWARD: 'Recompensa disponível',
    LEARN_TEAM_HEADER_DETAIL_REWARD_MOBILE: 'Recompensa',
    LEARN_TEAM_HEADER_DETAIL_DISABLED: 'Caminho desativado',
    LEARN_TEAM_HEADER_DETAIL_SUCESS: 'ROTA CONCLUÍDA',
    LABEL_SIDEBAR_ITEM_MY_COURSES_SUBTITLE:
      'Explore seus cursos atribuídos para um aprendizado personalizado',
    LABEL_SIDEBAR_ITEM_MY_COURSES_SUBTITLE_DESKTOP:
      'Explore seus caminhos designados para um aprendizado personalizado - comece hoje mesmo e desenvolva sua carreira!',
    LEARN_SKILL_PATH_SUMMARY_COURSE: 'Cursos',
    LEARN_SKILL_PATH_SUMMARY_QUIZ: 'Avaliações',
    LEARN_SKILL_PATH_SUMMARY_PROJECTS: 'Projetos',
    LEARN_CARD_REWARD_TITLE_COMPLETED_NEW:
      'Parabéns! Agora você tem *{{courseCount}} recompensas* que pode resgatar em cursos de sua escolha.',
    LEARN_CARD_REWARD_TITLE_TAKEN_NEW:
      'Você resgatou todas as recompensas disponíveis nesta rota.',
    LEARN_TEXT_DURATION: 'Duração do curso:',
    LEARN_TITLE_REWARDS_CLAIMEDS: 'Recompensas resgatadas',
    LEARN_SUBTITLE_REWARDS_CLAIMEDS:
      'Lembre-se de que você pode encontrar seus cursos resgatados na aba Meus Cursos.',
    LEARN_TEAM_HEADER_DETAIL_REWARD_IN_PROGRESS: 'Recompensas',
    LEARN_TEAM_HEADER_DETAIL_REWARD_TAKEN: 'Recompensas resgatadas',
    LEARN_TEAM_CRITERIA_COURSE_MANDATORY:
      'Número total de cursos que você precisa concluir para terminar um curso',
    LEARN_TEAM_CRITERIA_COMPLETE: 'Completo',
    LEARN_TEAM_CRITERIA_COURSE_SHORT: 'Curso obrigatório',
    LEARN_TEAM_CRITERIA_COURSE_SHORT_plural: 'Cursos obrigatórios',
    LEARN_TEAM_CRITERIA_QUIZ_MANDATORY:
      'Número de avaliações que faltam ser aprovadas para concluir o caminho',
    LEARN_TEAM_CRITERIA_QUIZ_SHORT_plural: 'avaliações',
    LEARN_TEAM_CRITERIA_QUIZ_SHORT: 'avaliação',
    LEARN_TEAM_CRITERIA_APPROVES: 'Passa',
    LEARN_TEAM_CRITERIA_PROJECT_MANDATORY:
      'Complete seu caminho e obtenha a certificação com os projetos {{count}} de sua escolha',
    LEARN_TEAM_CRITERIA_PROJECT_SHORT: 'Projeto',
    LEARNING_TEAM_TRACK_CONTENT_TRACK: 'Conteúdo do caminho',
    LEARN_TEAM_CRITERIA_PROJECT_SHORT_plural: 'Projetos',
    LEARN_TEXT_LINK: 'Link',
    LEARN_TEXT_EXTERNAL_LINK: 'Abrir conteúdo',
    LEARN_TEXT_COMPLETED_CRITERIAS:
      'Você completou todos os critérios de certificação',
    LEARN_DOWNLOAD_CERTIFICATION: 'Baixar certificado',
    TITLE_ERROR_VIEW_DETAIL_TRACK: 'Ocorreu um problema',
    SUBTITLE_ERROR_VIEW_DETAIL_TRACK:
      'Lamentamos, ocorreu um erro ao carregar a página. Por favor, atualize para resolvê-lo. Se o problema persistir, entre em contato com o serviço de atendimento ao cliente através do chat ou tente novamente mais tarde.',
    RELOAD_PAGE_ERROR: 'Recarregar página',
    TAG_REQUIRED: 'Obrigatório',
    DATE_LIMIT: 'Data limite',
    TRACK_COMPLETED: 'trilha concluída',
  },
};

export default translationsPT;

import { B2B_TEAM_COURSES } from '../constants';
import { TPageTranslationLocale } from './types';

const translationsEN: TPageTranslationLocale = {
  [B2B_TEAM_COURSES]: {
    EMPTY_SUBTITLE: 'Contact your administrator to assign you a route.',
    EMPTY_SUBTITLE_B2B_ALL_CATALOG:
      'Discover our extensive catalog while your administrator assigns you one.',
    ASSIGNED_TRACK: "You don't have anything assigned yet. ",
    ASSIGNED_TRACK_B2B_ALL_CATALOG:
      'You have not been assigned routes yet, but you can now start your learning experience',
    EXPIRATION_DATE: 'DATE OF DELIVERY:',
    STUDENTS: 'Students',
    END_DATE: 'Final date {{finalDate}}',
    TITLE_MINIMUM_REQUIREMENTS: 'Requirements to complete the learning route.',
    CTA_START_COURSE: 'Start Course',
    CTA_CONTINUE_PATH: 'Continue route',
    REWARDS_TITLE: 'What are my Rewards?',
    REWARDS_SUBTITLE: 'Learn more',
    REWARDS_COPY1: 'The Rewards are\n ',
    REWARDS_COPY2: 'When you finish your route\n ',
    REWARDS_COPY3: 'You will have at your disposal\n ',
    REWARDS_CTA: 'Understood',
    REWARDS_UNLOKED: 'rewards unlocked',
    REWARDS_INFO:
      'After completing your training, you have unlocked {{totalCourses}} additional course(s) in Crehana of your choice.',
    ENROLLED_PATH_COURSE_PROGRESS_LABEL: '{{progress}}% classes viewed',
    ENROLLED_PATH_COURSE_PROJECT_LABEL: 'Final project:',
    SKILL_XP_CARD_RESULTS_CTA: 'See Result',
    SKILL_XP_CARD_PENDING_CTA: 'Take Skill XP',
    LEARNING_TEAM_TRACK_CONTENT_TRACK: 'Content of the path',
    PROJECT_DIALOG_TITLE:
      'Select a course for which you want to carry out the project',
    PROJECT_DIALOG_DESCRIPTION:
      'Remember that to obtain your route certificate it is only mandatory to complete 1 project of the course you choose. ',
    PROJECT_DIALOG_DESCRIPTION_MOBILE:
      'Remember that to obtain your route diploma it is mandatory to complete at least 1 final project of your choice.',
    PROJECT_DIALOG_COURSES_TITLE: 'Courses without starting a project',
    PROJECT_TYPE_CERTIFIED: 'Certificate',
    PROJECT_TYPE_DRAFT: 'Pending',
    PROJECT_TYPE_FINISHED: 'Completed',
    PROJECT_TYPE_OBSERVED: 'With comments',
    PROJECT_TYPE_PUBLISHED: 'Under review',
    PROJECT_TYPE_RESOLVED: 'Pending',
    PROJECT_DIALOG_DELIVERABLE_TITLE: 'Course deliverable',
    PROJECT_DIALOG_SELECT_COURSE_PLACEHOLDER:
      'Select a course to view the deliverable here.',
    PROJECT_DIALOG_PENDING_PROJECT_SUBTITLE: 'Last project in progress',
    LEARN_ENROLLED_PATH_PANEL_COURSES_TITLE:
      'Choose your final project and complete the learning path.',
    LEARN_ENROLLED_PATH_PANEL_COURSES_TITLE__plural:
      'Choose {{count}} courses to complete the final projects and complete the route.',
    LEARN_ENROLLED_PATH_PANEL_PENDING_PROJECT_TITLE:
      'Continue your final project and get certified on the learning path.',
    ENROLLED_PATH_START_PROJECT_LABEL: 'Start a project',
    ENROLLED_PATH_CONTINUE_PROJECT_LABEL: 'Continue project',
    ENROLLED_PATH_START_DOWNLOAD_CERTIFICATE: 'Download diploma',
    REWARD_CARD_TRACK_IN_PROGRESS_TITLE: 'Great job so far, {{username}}!',
    REWARD_CARD_TRACK_IN_PROGRESS_SUBTITLE:
      'Continue completing your study path so you can choose free courses of interest to you.',
    REWARD_CARD_TRACK_IN_PROGRESS_CTA: 'Learn more',
    REWARD_CARD_TRACK_NOT_STARTED_TITLE:
      'Have {{count}} free courses of your choice!',
    REWARD_CARD_TRACK_NOT_STARTED_SUBTITLE:
      'Complete your learning path to choose {{count}} free courses of your interest.',
    REWARD_CARD_TRACK_NOT_STARTED_CTA: 'Learn more',
    REWARD_CARD_TRACK_COMPLETED_TITLE: 'Great job, {{username}}!',
    REWARD_CARD_TRACK_COMPLETED_SUBTITLE:
      'Now that you have completed your study path you will be able to access your favorite courses.',
    REWARD_CARD_TRACK_COMPLETED_CTA: 'Choose my courses',
    STATUS_ITEM_IN_PROGRESS: 'In progress',
    STATUS_ITEM_MANDATORY_COURSES: 'Mandatory',
    STATUS_ITEM_MANDATORY_PROJECTS: 'Project',
    STATUS_ITEM_MANDATORY_PROJECTS_plural: 'Projects',
    WARNING_DAYS_REMAINING: 'Lack {{count}} day',
    WARNING_DAYS_REMAINING_plural: 'Missing {{count}} days',
    WARNING_DAYS_DUE: 'Delay of {{count}} day',
    WARNING_DAYS_DUE_plural: 'Delay {{count}} days',
    COMPLETE_ALL_COURSES_MESSAGE_TITLE: 'How to get certified?',
    COMPLETE_ALL_COURSES_MESSAGE:
      'You must complete 100% viewing of the number of required courses and approve the number of required projects.',
    LEARNING_PATHS_LEFT_SIDE_TITLE: 'Learning path',
    LEARNING_PATHS_LEFT_SIDE_TITLE_plural: 'Learning paths',
    LEARNING_TEAM_TRACK_CONTENT_FOR_YOU: 'See Content for you',
    LEARN_TEAM_TRACK_COMPLETE_SKILLPATH: 'Complete the Skill XP',
    LEARN_TEAM_TRACK_X_ASSIGNED_COURSES: '{{count}} Assigned',
    LEARN_TEAM_TRACK_X_ASSIGNED_COURSES_plural: '{{count}} Assigned',
    LEARN_TEAM_TRACK_X_MANDATORY_COURSES: '{{count}} Mandatory',
    LEARN_TEAM_TRACK_X_MANDATORY_COURSES_plural: '{{count}} Mandatory',
    LEARN_TEAM_TRACK_X_MANDATORY_PROJECTS: '{{count}} Project',
    LEARN_TEAM_TRACK_X_MANDATORY_PROJECTS_plural: '{{count}} Projects',
    LEARN_TEAM_TRACK_COMPLETED: 'Completed',
    LEARN_TEAM_TRACK_REWARD_QUANTITY: 'Won',
    LEARN_TEAM_TRACK_PROJECT_LABEL: 'Project',
    LEARN_TEAM_SKIP: 'Not now',
    LEARN_TEAM_EXTERNAL: 'External content',
    LEARN_TEAM_EXTERNAL_ASK: 'Do you want to mark the content as viewed?',
    LEARN_TEAM_TRACK_SUCCESS: 'You completed the learning path!',
    LEARN_TEAM_TRACK_SUCCESS_LABEL: 'You got',
    LEARN_TEAM_TRACK_TOOLTIP_BLOCK:
      'You must complete previous courses to view these',
    LEARN_TEAM_TRACK_BLOCK_DESCRIPTION:
      'To start this course you must previously see the following:',
    LEARN_TEAM_TRACK_MANDATORY_LABEL: 'Mandatory Course',
    LEARN_TEAM_TRACK_OPTIONAL_LABEL: 'Optional Course',
    LEARN_TEAM_TRACK_OPTIONAL_LABEL_SHORT: 'Optional',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_START_CURSO: 'Start course',
    LEARN_TEAM_TRACK_MEDIUM: 'You are doing very well, keep it up!',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_START_EVALUATION: 'Start evaluation',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_START_PROJECT: 'Start a project',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_VIEW_TRAILER: 'See trailer',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_GO_BACK: 'Go back',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_OPEN_CONTENT: 'Open content',
    LEARN_TEAM_TRACK_OPTIONAL_MENU_MARK_AS_WATCHED: 'Mark as seen',
    LEARN_TEAM_TRACK_CUSTOM_LABEL: 'Personalized',
    LEARN_TEAM_TRACK_SHOW_PROJECT: 'Show project',
    LEARN_TEAM_TRACK_CONTINUE_PROJECT: 'Continue project',
    LEARN_TEAM_TRACK_CHOOSE_MISSING_PROJECTS: 'Choose missing projects',
    LEARN_TEAM_TRACK_START_PROJECT: 'Start a final project',
    LEARN_TEAM_TRACK_ACADEMY_TAG: 'Academy',
    LEARN_TEAM_TRACK_COURSE_CARD_ARIA_LABEL_CTA: 'Continue course',
    LEARN_TEAM_SEE_LESS: 'See fewer courses',
    LEARN_TEAM_MORE: 'see more',
    LEARN_TEAM_HELP:
      'Complete your learning path and you can choose *{{courseCount}} free Crehana courses* of your interest.',
    LEARN_TEAM_SEE_MORE: 'See more courses on the route',
    LEARN_TEAM_STATE_INTEGRATION: 'Mark as seen',
    COURSE_CARD_COURSE_REQUIRED: 'course{{s}} required{{s}}',
    COURSE_CARD_REQUIREMENTS_POPUP_TITLE: 'Required courses',
    COURSE_CARD_REQUIREMENTS_POPUP_SUBTITLE:
      'To start this course you must previously see the following:',
    COURSE_CARD_REQUIREMENTS_POPUP_CTA: 'Understood',
    LEARN_TEAM_HELP_ONE: 'Rewards are study bonuses to access free courses.',
    LEARN_TEAM_HELP_TWO:
      'At the end of your learning path you will be able to choose your favorite courses.',
    LEARN_TEAM_HELP_THREE:
      'You will have at your disposal all the courses in the Crehana catalog',
    LEARN_TEAM_HEADER_OPTION_ALL: 'All',
    LEARN_TEAM_HEADER_OPTION_DEFEATED: 'Due Soon',
    LEARN_TEAM_HEADER_OPTION_EXPIRED: 'Overdue',
    LEARN_TEAM_HEADER_OPTION_DISABLED: 'Disabled',
    LEARN_TEAM_HEADER_OPTION_COMPLETEDS: 'Completed',
    LEARN_TEAM_HEADER_SEARCH: 'Search by route title',
    LEARN_TEAM_EMPTY_TITLE: 'You do not have assigned routes',
    LEARN_TEAM_EMPTY_FILTER_NONE: 'Try another name',
    LEARN_TEAM_HEADER_FILTER: 'FILTERS',
    LEARN_TEAM_HEADER_DETAIL_DEFEATED: 'Expires',
    LEARN_TEAM_HEADER_DETAIL_EXPIRED: 'Expired',
    LEARN_TEAM_HEADER_DETAIL_ASSIGNED: 'Assigned by',
    LEARN_TEAM_HEADER_DETAIL_REWARD: 'Reward available',
    LEARN_TEAM_HEADER_DETAIL_REWARD_MOBILE: 'Reward',
    LEARN_TEAM_OPTION: 'More actions',
    LEARN_TEAM_TIME: 'Time left: {{duration}}',
    LEARN_TEAM_TIME_DURATION: 'Course duration: {{duration}}',
    LEARN_TEAM_COURSE_REQUIRED_LABEL: 'Display course',
    LEARN_TEAM_QUIZ_REQUIRED_LABEL: 'Pass evaluation',
    LEARN_TEAM_PROJECT_REQUIRED_LABEL: 'Approve project',
    LEARN_TEAM_HEADER_DETAIL_DISABLED: 'Route disabled',
    LEARN_TEAM_CRITERIA: 'Certification criteria',
    LEARN_TEAM_CRITERIA_COURSE_MANDATORY:
      'Total number of courses that you must complete to finish a route.',
    LEARN_TEAM_CRITERIA_COMPLETE: 'Complete',
    LEARN_TEAM_CRITERIA_COURSE_SHORT: 'Required course',
    LEARN_TEAM_CRITERIA_COURSE_SHORT_plural: 'Required courses',
    LEARN_TEAM_CRITERIA_QUIZ_MANDATORY:
      'Number of evaluations that remain to be passed to complete the route',
    LEARN_TEAM_CRITERIA_QUIZ_SHORT: 'evaluations',
    LEARN_TEAM_CRITERIA_QUIZ_SHORT_plural: 'evaluation',
    LEARN_TEAM_CRITERIA_APPROVES: 'Approve',
    LEARN_TEAM_CRITERIA_PROJECT_MANDATORY:
      'Complete your route and certify with {{count}} projects of your choice.',
    LEARN_TEAM_CRITERIA_PROJECT_SHORT: 'Project',
    LEARN_TEAM_CRITERIA_PROJECT_SHORT_plural: 'Projects',
    LEARN_TEAM_HEADER_DETAIL_DISABLED_DESCRIPTION:
      'Your administrator disabled this route.',
    LEARN_TEAM_HEADER_DETAIL_SUCESS: 'COMPLETED ROUTE',
    LABEL_SIDEBAR_ITEM_MY_COURSES_SUBTITLE:
      'Explore your assigned routes for personalized learning.',
    LABEL_SIDEBAR_ITEM_MY_COURSES_SUBTITLE_DESKTOP:
      'Explore your assigned routes for personalized learning. ',
    LEARN_SKILL_PATH_SUMMARY_COURSE: 'courses',
    LEARN_SKILL_PATH_SUMMARY_QUIZ: 'Evaluations',
    LEARN_SKILL_PATH_SUMMARY_PROJECTS: 'Projects',
    LEARN_CARD_REWARD_TITLE_COMPLETED_NEW:
      'Congratulation! you now have *{{courseCount}} rewards* that you can redeem for courses of your choice.',
    LEARN_CARD_REWARD_TITLE_TAKEN_NEW:
      'You have redeem all available rewards on this path.',
    LEARN_TEXT_DURATION: 'Course duration:',
    LEARN_TITLE_REWARDS_CLAIMEDS: 'Redeemed Rewards',
    LEARN_SUBTITLE_REWARDS_CLAIMEDS:
      'Remember that you can find your redeem courses in the My Courses tab.',
    LEARN_TEAM_HEADER_DETAIL_REWARD_IN_PROGRESS: 'Rewards',
    LEARN_TEAM_HEADER_DETAIL_REWARD_TAKEN: 'Redeemed Rewards',
    LEARN_TEXT_LINK: 'Link',
    LEARN_TEXT_EXTERNAL_LINK: 'Open content',
    LEARN_TEXT_COMPLETED_CRITERIAS:
      'You have completed all certification criteria',
    LEARN_DOWNLOAD_CERTIFICATION: 'Download certificate',
    TITLE_ERROR_VIEW_DETAIL_TRACK: 'Something went wrong',
    SUBTITLE_ERROR_VIEW_DETAIL_TRACK:
      "We're sorry, an error occurred while loading the page. Please refresh to resolve it. If the issue persists, contact customer service via chat or try again later.",
    RELOAD_PAGE_ERROR: 'Reload page',
    TAG_REQUIRED: 'Required',
    DATE_LIMIT: 'Due date',
    TRACK_COMPLETED: 'Track completed',
  },
};

export default translationsEN;

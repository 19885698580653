import { HELP_LINK_COLLABORATOR } from "../shared";
import { COLLABORATOR_LEARNING_MODULE } from "./collaborator-learning-module";
export var FULL_SUITE_COLLABORATOR_LINKS = {
  MENU: [
  // Home
  {
    type: 'url',
    code: 'HOME',
    label: 'MODULE_HOME',
    icon: 'outline/Home',
    url: "/org/[orgSlug]/home/",
    tooltip: 'MODULE_HOME',
    accentedColor: false
  },
  // My team
  {
    type: 'module',
    code: 'MY_TEAM_MODULE',
    label: 'Mi equipo',
    icon: 'outline/People',
    arrowDefault: true,
    accentedColor: false,
    tooltip: {
      title: 'Mi equipo',
      description: 'Monitorea los procesos activos y resultados de tu equipo.'
    },
    children: [
    // Active processes
    {
      type: 'url',
      code: 'MY_TEAM_MODULE.FOLLOW_UP',
      label: 'Seguimiento',
      icon: 'outline/Power',
      tooltip: {
        title: 'Procesos activos',
        description: 'Gestiona la participación de tu equipo de forma centralizada.'
      }
    },
    // Team results
    {
      type: 'url',
      code: 'ANALYTICS.LEADER_RESULTS',
      label: 'Resultados',
      icon: 'outline/Presentation',
      tooltip: {
        title: 'Resultados',
        description: 'Analiza los resultados de los procesos finalizados.'
      }
    }]
  },
  // Collaborator results
  {
    type: 'url',
    code: 'ANALYTICS.COLLABORATOR_RESULTS',
    label: 'Mis resultados',
    icon: 'outline/Presentation',
    accentedColor: false,
    tooltip: {
      title: 'Mis resultados',
      description: 'Consulta los resultados de los procesos en los que participas.'
    }
  },
  // Company
  {
    type: 'module',
    label: 'Compañía',
    code: 'COMPANY',
    icon: 'outline/Building',
    accentedColor: false,
    arrowDefault: true,
    tooltip: {
      title: 'Compañía',
      description: 'Conoce a los miembros de tu compañía.'
    },
    children: [
    // This link is temporarily hidden
    // {
    //   type: 'url',
    //   code: 'COMPANY.COMPANY_DIRECTORY',
    //   label: 'Directorio',
    //   icon: 'outline/Recruitment',
    //   tooltip: {
    //     title: 'Directorio',
    //     description: 'Consulta los miembros de tu compañía.',
    //   },
    // },
    {
      type: 'url',
      code: 'COMPANY.COMPANYCHART',
      label: 'Organigrama',
      icon: 'outline/Departments',
      url: '/org/[orgSlug]/hris/org-chart/',
      tooltip: {
        title: 'Organigrama',
        description: 'Conoce la estructura de la compañía'
      }
    }]
  },
  // Products
  {
    type: 'group',
    code: 'PRODUCTS_MODULE',
    label: 'Productos',
    children: [
    // Management
    {
      type: 'module',
      code: 'MODULE_MANAGEMENT',
      label: 'Gestion',
      icon: 'logos/Gestion',
      decoratedIcon: true,
      arrowDefault: true,
      tooltip: {
        title: 'Gestión',
        description: 'Gestiona tus documentos y solicitudes de tiempo libre.'
      },
      children: [{
        type: 'url',
        code: 'MODULE_MANAGEMENT.DOCUMENTS',
        label: 'Documentos corporativos',
        icon: 'outline/FolderAdd',
        isLocked: true,
        url: '/org/[orgSlug]/hris/corporate-documents/personal/',
        tooltip: {
          title: 'Documentos',
          description: 'Proporciona o firma los documentos que te solicita la compañía.'
        }
      }, {
        type: 'dropdown',
        code: 'MODULE_MANAGEMENT.TIME_OFF',
        label: 'Tiempo libre',
        icon: 'outline/ColorPicker',
        tooltip: {
          title: 'Tiempo libre',
          description: 'Conoce y solicita las políticas de tiempo libre que ofrece tu compañía.'
        },
        children: [{
          type: 'url',
          code: 'MODULE_MANAGEMENT.TIME_OFF.REQUEST',
          label: 'Solicitar tiempo libre',
          url: '/org/[orgSlug]/hris/time-off/policies/'
        }, {
          type: 'url',
          code: 'MODULE_MANAGEMENT.TIME_OFF.REQUESTS_LIST',
          label: 'Solicitudes realizadas',
          url: '/org/[orgSlug]/hris/time-off/requests/'
        }, {
          type: 'url',
          code: 'MODULE_MANAGEMENT.TIME_OFF.CALENDAR',
          label: 'Calendario',
          url: '/org/[orgSlug]/hris/time-off/calendar/'
        }]
      }]
    },
    // Recognition
    {
      type: 'module',
      code: 'RECOGNITION',
      label: 'Reconocimiento',
      icon: 'logos/Recognition',
      decoratedIcon: true,
      arrowDefault: true,
      tooltip: {
        title: 'Reconocimiento',
        description: 'Reconoce y promueve los logros con medallas.'
      },
      children: [{
        type: 'url',
        code: 'RECOGNITION.RECOGNITION',
        label: 'Medallas',
        icon: 'outline/Badge',
        tooltip: {
          title: 'Medallas',
          description: 'Envía medallas a tus compañero y mira las que recibiste.'
        }
      }, {
        type: 'url',
        code: 'RECOGNITION.RECOGNITION_REDEMPTION',
        label: 'Recompensas',
        icon: 'outline/Gift',
        tooltip: {
          title: 'Recompensas',
          description: 'Canjea los puntos que obtuviste por tus logros'
        }
      }]
    },
    // Performance
    {
      type: 'module',
      code: 'PERFORMANCE',
      label: 'Desempeño',
      icon: 'logos/Performance',
      decoratedIcon: true,
      arrowDefault: true,
      tooltip: {
        title: 'Desempeño',
        description: 'Gestiona tus objetivos y plan de desarrollo.'
      },
      children: [{
        type: 'dropdown',
        code: 'PERFORMANCE.OKRS_PERFORMANCE',
        label: 'OKRs',
        icon: 'outline/OKRs',
        tooltip: {
          title: 'OKRs',
          description: 'Visualiza el progreso de la estrategia empresarial y reconoce el esfuerzo para cumplir los objetivos'
        },
        children: [{
          type: 'url',
          code: 'PERFORMANCE.OKRS_PERFORMANCE.OKRS',
          label: 'Objetivos'
        }, {
          type: 'url',
          code: 'PERFORMANCE.OKRS_PERFORMANCE.OKRS_ALIGNMENT',
          label: 'Alineación'
        }]
      }, {
        type: 'url',
        code: 'PERFORMANCE.GOALS',
        label: 'Metas',
        icon: 'outline/Flag',
        tooltip: {
          title: 'Metas',
          description: 'Planea y establece tus compromisos de trabajo'
        }
      }, {
        type: 'url',
        code: 'PERFORMANCE.COMPETENCES',
        label: 'Competencias',
        icon: 'outline/Report',
        tooltip: {
          title: 'Competencias',
          description: 'Accede a los cuestionarios de los procesos asignados'
        }
      }, {
        type: 'url',
        code: 'PERFORMANCE.FEEDBACK',
        label: 'Feedback',
        icon: 'outline/AppsComments',
        tooltip: {
          title: 'Feedback',
          description: 'Entrega retroalimentación asertiva, documéntala e incentiva esta cultura'
        }
      }, {
        type: 'url',
        code: 'PERFORMANCE.PID',
        label: 'Plan individual de desarrollo',
        icon: 'outline/TrendingUp',
        tooltip: {
          title: 'Plan individual de desarrollo',
          description: 'Programa actividades para tu desarrollo personal y profesional'
        }
      }, {
        // Leader link
        type: 'url',
        code: 'PERFORMANCE.SUCCESSION_PLAN',
        label: 'Plan de sucesión',
        icon: 'outline/SuccessionPlan',
        tooltip: {
          title: 'Plan de sucesión',
          description: 'Crea planes de acción para futuras vacantes.'
        }
      }]
    },
    // Climate
    {
      type: 'module',
      label: 'Clima',
      code: 'CLIMATE_ENGAGEMENT',
      icon: 'logos/Climate',
      decoratedIcon: true,
      arrowDefault: true,
      tooltip: {
        title: 'Clima',
        description: 'Gestiona tus horarios y horas de trabajo.'
      },
      children: [{
        type: 'url',
        code: 'CLIMATE_ENGAGEMENT.CLIMATE',
        label: 'Evaluaciones de clima',
        icon: 'outline/ActivityCheck',
        tooltip: {
          title: 'Evaluaciones de clima',
          description: 'Accede al cuestionario para mediar el clima organizacional'
        }
      }, {
        type: 'url',
        code: 'CLIMATE_ENGAGEMENT.SURVEY',
        label: 'Encuestas',
        icon: 'outline/File',
        tooltip: {
          title: 'Encuestas',
          description: 'Ayuda a tu empresa a recolectar información de manera rápida y sencilla'
        }
      }]
    },
    // Learning
    COLLABORATOR_LEARNING_MODULE,
    // Remote work
    {
      type: 'url',
      code: 'MODULE_REMOTE_WORK',
      label: 'Trabajo remoto',
      decoratedIcon: true,
      icon: 'logos/RemoteWork',
      tooltip: {
        title: 'Trabajo remoto',
        description: 'Registra el rendimiento de tu jornada laboral desde casa.'
      }
    },
    // Recruitment
    {
      type: 'url',
      code: 'MODULE_RECRUITMENT',
      label: 'Reclutamiento',
      decoratedIcon: true,
      icon: 'logos/Reclutamiento',
      tooltip: {
        title: 'Reclutamiento',
        description: 'Gestiona el reclutamiento y la selección del talento de tu compañía.'
      }
    }]
  }],
  FOOTER: [HELP_LINK_COLLABORATOR]
};
import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import * as Apollo from '@apollo/client';
var defaultOptions = {};
export var CompassLayoutsGetOrganizationByLearningSlugQueryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "CompassLayoutsGetOrganizationByLearningSlugQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "organizationSlug"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "get_organization_by_learning_slug"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "organization_slug"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "organizationSlug"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "original_id"
            }
          }]
        }
      }]
    }
  }]
};

/**
 * __useCompassLayoutsGetOrganizationByLearningSlugQueryQuery__
 *
 * To run a query within a React component, call `useCompassLayoutsGetOrganizationByLearningSlugQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompassLayoutsGetOrganizationByLearningSlugQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompassLayoutsGetOrganizationByLearningSlugQueryQuery({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useCompassLayoutsGetOrganizationByLearningSlugQueryQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);
  return Apollo.useQuery(CompassLayoutsGetOrganizationByLearningSlugQueryDocument, options);
}
export function useCompassLayoutsGetOrganizationByLearningSlugQueryLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);
  return Apollo.useLazyQuery(CompassLayoutsGetOrganizationByLearningSlugQueryDocument, options);
}
import * as Types from '../../../../generated/graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCentralizedOrganizationQueryVariables = Types.Exact<{
  organizationSlug: Types.Scalars['String']['input'];
}>;


export type GetCentralizedOrganizationQuery = { __typename?: 'Query', get_organization_by_learning_slug?: { __typename?: 'CentralizedOrganizationNode', original_id: number, name: string, slug: string, user_create_template: string, id: number, licenses?: { __typename?: 'LicensesNode', available_licenses: number } | null } | null };


export const GetCentralizedOrganizationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCentralizedOrganization"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationSlug"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"get_organization_by_learning_slug"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"organization_slug"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationSlug"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"id"},"name":{"kind":"Name","value":"original_id"}},{"kind":"Field","name":{"kind":"Name","value":"original_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"user_create_template"}},{"kind":"Field","name":{"kind":"Name","value":"licenses"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"available_licenses"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetCentralizedOrganizationQuery__
 *
 * To run a query within a React component, call `useGetCentralizedOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCentralizedOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCentralizedOrganizationQuery({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useGetCentralizedOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetCentralizedOrganizationQuery, GetCentralizedOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCentralizedOrganizationQuery, GetCentralizedOrganizationQueryVariables>(GetCentralizedOrganizationDocument, options);
      }
export function useGetCentralizedOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCentralizedOrganizationQuery, GetCentralizedOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCentralizedOrganizationQuery, GetCentralizedOrganizationQueryVariables>(GetCentralizedOrganizationDocument, options);
        }
export type GetCentralizedOrganizationQueryHookResult = ReturnType<typeof useGetCentralizedOrganizationQuery>;
export type GetCentralizedOrganizationLazyQueryHookResult = ReturnType<typeof useGetCentralizedOrganizationLazyQuery>;
export type GetCentralizedOrganizationQueryResult = Apollo.QueryResult<GetCentralizedOrganizationQuery, GetCentralizedOrganizationQueryVariables>;
import { useQuery } from '@apollo/client';

import GET_PENDING_DASHBOARD_SKILL_XP_EVOLUTION_QUERY from '../graphql/GetPendingDashboardSkillXPEvolution.v4.b2b.graphql';
import type {
  GetPendingDashboardSkillXPEvolutionQuery,
  GetPendingDashboardSkillXPEvolutionQueryVariables,
} from '../graphql/types/GetPendingDashboardSkillXPEvolutionQuery';

const useGetPendingDashboardSkillXPEvolution = (userOrganizationId: number) => {
  const { data, loading, error, refetch } = useQuery<
    GetPendingDashboardSkillXPEvolutionQuery,
    GetPendingDashboardSkillXPEvolutionQueryVariables
  >(GET_PENDING_DASHBOARD_SKILL_XP_EVOLUTION_QUERY, {
    context: { clientName: 'v4.b2b' },
    variables: { userId: userOrganizationId },
    skip: userOrganizationId === undefined || userOrganizationId === null,
  });

  const pendingTests =
    data?.skillXpUsers?.filter(
      el => el?.status === 1 && el.isExpired === false,
    ) || [];
  const pendingAndExpiredTests =
    data?.skillXpUsers?.filter(el => el?.status === 1) || [];
  const solvedTests = data?.skillXpUsers?.filter(el => el?.status === 2) || [];
  const totalTests = data?.skillXpUsers?.length || 0;

  return {
    pendingTests,
    pendingAndExpiredTests,
    totalTests,
    solvedTests,
    loading,
    error,
    refetch,
  };
};

export default useGetPendingDashboardSkillXPEvolution;

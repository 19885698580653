import { useCompassLayoutsGetOrganizationByLearningSlugQueryQuery } from "./graphql/CompassLayoutsGetOrganizationByLearningSlugQuery.v5.generated";
var useCentralizedOrgSlug = function useCentralizedOrgSlug(_ref) {
  var _data$get_organizatio, _data$get_organizatio2, _data$get_organizatio3, _data$get_organizatio4;
  var organizationSlug = _ref.organizationSlug;
  var _useCompassLayoutsGet = useCompassLayoutsGetOrganizationByLearningSlugQueryQuery({
      context: {
        clientName: 'v5'
      },
      variables: {
        organizationSlug: organizationSlug
      },
      skip: !organizationSlug
    }),
    data = _useCompassLayoutsGet.data,
    loading = _useCompassLayoutsGet.loading,
    error = _useCompassLayoutsGet.error;
  return {
    centralizedOrgSlug: (_data$get_organizatio = data === null || data === void 0 ? void 0 : (_data$get_organizatio2 = data.get_organization_by_learning_slug) === null || _data$get_organizatio2 === void 0 ? void 0 : _data$get_organizatio2.slug) !== null && _data$get_organizatio !== void 0 ? _data$get_organizatio : '',
    centralizedId: (_data$get_organizatio3 = data === null || data === void 0 ? void 0 : (_data$get_organizatio4 = data.get_organization_by_learning_slug) === null || _data$get_organizatio4 === void 0 ? void 0 : _data$get_organizatio4.original_id.toString()) !== null && _data$get_organizatio3 !== void 0 ? _data$get_organizatio3 : '',
    loading: loading,
    error: !!error
  };
};
export default useCentralizedOrgSlug;
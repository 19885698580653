import { useGetCentralizedOrganizationQuery } from './graphql/GetCentralizedOrganization.v5.generated';

export const useCentralizedOrganization = (slug?: string) => {
  const { data, loading } = useGetCentralizedOrganizationQuery({
    variables: {
      organizationSlug: slug as string,
    },
    skip: !slug,
    context: { clientName: 'v5' },
  });

  return {
    isLoading: loading,
    originalId: data?.get_organization_by_learning_slug?.original_id,
    slug: data?.get_organization_by_learning_slug?.slug,
  };
};
